<template>
    <infinite-loading
        @infinite="action"
        :identifier="identifierLocal"
        :direction="direction"
    >
        <div class="py-3" v-if="loading">
            <v-progress-circular
                width="5"
                indeterminate
                color="secondary"
            ></v-progress-circular>
        </div>
        <div slot="spinner"></div>
        <div slot="no-more">
            <slot name="complete"></slot>
        </div>
        <div slot="no-results">
            <slot name="empty"></slot>
        </div>
    </infinite-loading>
</template>

<script>
import InfiniteLoading from "vue-infinite-loading";

export default {
    name: "base-infinite-scroll",

    components: {
        InfiniteLoading,
    },

    props: {
        action: Function,
        identifier: [Number, null],
        direction: [String, null],
        loading: Boolean,
    },

    data() {
        return {
            identifierLocal: this.identifier,
        };
    },

    watch: {
        identifier(val) {
            this.identifierLocal = val;
        },
        identifierLocal(val) {
            this.$emit("update:identifier", val);
        },
    },
};
</script>
