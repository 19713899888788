<template>
    <base-dialog width="540" :is-open.sync="isOpenLocal">
        <v-card-title style="background-color: #f1eeee">
            <span style="color: #78756a" class="font-weight-bold"
                >View Prayer Request
            </span>
        </v-card-title>
        <v-card-text>
            <v-row class="px-5 my-2">
                <v-col cols="12">
                    <v-row>
                        <v-col cols="12" md="4" class="font-weight-bold">
                            REQUESTED BY
                        </v-col>

                        <v-col cols="12" md="8" class="font-size-16">
                            {{ fullName }}
                        </v-col>
                    </v-row>
                </v-col>

                <v-col cols="12">
                    <v-row>
                        <v-col cols="12" md="4" class="font-weight-bold">
                            DATE UPLOADED
                        </v-col>

                        <v-col cols="12" md="8" class="font-size-16">
                            {{ formattedDate }}
                        </v-col>
                    </v-row>
                </v-col>

                <v-col cols="12">
                    <v-row>
                        <v-col cols="12" md="4" class="font-weight-bold">
                            PRAYER COUNT
                        </v-col>

                        <v-col cols="12" md="8" class="font-size-16">
                            {{ data.prayer_count }}
                        </v-col>
                    </v-row>
                </v-col>

                <v-col cols="12">
                    <v-row>
                        <v-col cols="12" md="4" class="font-weight-bold">
                            PRAYER REQUEST
                        </v-col>

                        <v-col cols="12" md="8" class="font-size-16">
                            {{ data.prayer }}
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-card-text>
    </base-dialog>
</template>

<script>
import BaseDialog from "@/components/Base/BaseDialog";
import dateUtility from "@/mixins/date";

export default {
    name: "engage-prayer-request-information-dialog",

    components: { BaseDialog },

    props: {
        isOpen: { type: Boolean, default: false, required: true },
        data: { type: [Object, null], required: false },
    },

    data() {
        return {
            isOpenLocal: this.isOpen,
        };
    },

    computed: {
        formattedDate() {
            return dateUtility.formatTimestampToSchedule(
                dateUtility.toLocalDate(this.data.created_at)
            );
        },

        fullName() {
            const { prefix, first_name, last_name, suffix } =
                this.data.requested_by;

            return `${prefix} ${first_name} ${last_name} ${
                suffix || ""
            }`.trim();
        },
    },

    watch: {
        isOpen(value) {
            this.isOpenLocal = value;
        },

        isOpenLocal(value) {
            this.$emit("update:isOpen", value);
        },
    },

    methods: {
        closeForm() {
            this.$emit("close");
        },
    },
};
</script>

<style lang="scss" scoped>
.close-icon {
    transition: 0.3s;
    border-radius: 50px !important;
    margin-top: 20px;
    margin-right: 15px;
    padding: 3px;
    background-color: #2c423d;
    color: white;
}
</style>
