<template>
    <base-dialog width="500" :is-open.sync="isOpenLocal">
        <v-card-title
            class="align-center justify-start"
            style="background-color: #f1eeee"
        >
            <span class="secondary--text font-weight-bold">Date Range</span>
        </v-card-title>

        <v-card-text class="pt-7 px-2">
            <v-container fluid>
                <v-row>
                    <v-col cols="12">
                        <base-input-label>Start Date</base-input-label>
                        <base-date-picker
                            color="secondary"
                            solo
                            rounded
                            custom-class="rounded-lg"
                            flat
                            hide-details
                            v-model="startDateLocal"
                        ></base-date-picker>
                    </v-col>
                    <v-col cols="12">
                        <base-input-label>End Date</base-input-label>
                        <base-date-picker
                            color="secondary"
                            solo
                            rounded
                            custom-class="rounded-lg"
                            flat
                            hide-details
                            :disabled="!startDateLocal"
                            :min="startDateLocal"
                            v-model="endDateLocal"
                        ></base-date-picker>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>

        <v-card-actions class="pa-0">
            <v-container fluid>
                <v-row align="center" justify="center" class="py-1 px-2">
                    <v-col md="2" cols="12" class="px-2">
                        <v-btn
                            block
                            depressed
                            dark
                            color="#389877"
                            rounded
                            @click="modify"
                            >Save</v-btn
                        >
                    </v-col>
                </v-row>
            </v-container>
        </v-card-actions>
    </base-dialog>
</template>

<script>
import dateMixin from "@/mixins/date";
import BaseDatePicker from "@/components/Base/BaseDatePicker";
import BaseInputLabel from "@/components/Base/BaseInputLabel";
import BaseDialog from "@/components/Base/BaseDialog";

export default {
    name: "base-date-range-dialog",

    components: { BaseDialog, BaseInputLabel, BaseDatePicker },

    props: {
        isOpen: Boolean,
        startDate: [String, null],
        endDate: [String, null],
        selectedDateRange: [String, null],
    },

    data() {
        return {
            isOpenLocal: this.isOpen,
            startDateLocal: null,
            endDateLocal: null,
        };
    },

    computed: {
        isFormValid() {
            return this.startDateLocal && this.endDateLocal;
        },
    },

    watch: {
        isOpen(value) {
            this.isOpenLocal = value;
        },
        isOpenLocal(value) {
            this.$emit("update:isOpen", value);
        },
        startDate(value) {
            this.startDateLocal = value;
        },
        // startDateLocal(value) {
        //     this.$emit("update:startDate", value);
        // },
        endDate(value) {
            this.endDateLocal = value;
        },
        // endDateLocal(value) {
        //     this.$emit("update:endDate", value);
        // },
    },

    methods: {
        modify() {
            this.$emit("update:startDate", this.startDateLocal);
            this.$emit("update:endDate", this.endDateLocal);
            this.$emit(
                "update:selectedDateRange",
                `${dateMixin.toSimpleDate(
                    this.startDateLocal
                )} - ${dateMixin.toSimpleDate(this.endDateLocal)}`
            );
            this.isOpenLocal = false;
            this.$emit("onModify");
        },
    },
};
</script>
