<template>
    <base-dialog width="500" :title="title" :is-open.sync="isOpenLocal">
        <v-card-text class="pt-7 px-2">
            <v-container fluid>
                <v-row no-gutters justify="center" class="mb-5">
                    <v-col cols="12">
                        <v-text-field
                            class="rounded-lg"
                            placeholder="Search"
                            solo
                            rounded
                            flat
                            hide-details
                            dense
                            color="secondary"
                            v-model="content.options.search"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <template v-for="(item, index) in content.items">
                    <div :key="index">
                        <v-list-item>
                            <v-list-item-title
                                class="accent--text font-weight-bold"
                            >
                                <span v-if="itemText">
                                    {{ item[itemText] }}
                                </span>
                                <slot name="text" :item="item" v-else></slot>
                            </v-list-item-title>
                            <v-list-item-action>
                                <v-btn icon @click="onSelect(item)">
                                    <v-icon>mdi-chevron-right</v-icon>
                                </v-btn>
                            </v-list-item-action>
                        </v-list-item>
                        <v-divider
                            v-if="index !== content.items.length - 1"
                        ></v-divider>
                    </div>
                </template>
            </v-container>

            <div class="text-center">
                <v-progress-circular
                    indeterminate
                    v-if="isLoading"
                ></v-progress-circular>
            </div>

            <base-infinite-scroll
                :action="getItems"
                :identifier="infiniteId"
            ></base-infinite-scroll>
        </v-card-text>
    </base-dialog>
</template>

<script>
import BaseDialog from "@/components/Base/BaseDialog";
import helperUtility from "@/mixins/helper";
import BaseInfiniteScroll from "@/components/Base/BaseInfiniteScroll";

export default {
    name: "custom-search-dialog",

    components: { BaseInfiniteScroll, BaseDialog },

    props: {
        isOpen: Boolean,
        title: String,
        action: String,
        itemText: String,
        itemDescription: String,
    },

    data() {
        return {
            infiniteId: +new Date(),
            isOpenLocal: this.isOpen,
            isLoading: false,
            content: {
                items: [],
                options: {
                    page: 1,
                    perPage: 10,
                    search: null,
                    sortBy: "asc",
                },
            },
        };
    },

    watch: {
        isOpen(value) {
            this.isOpenLocal = value;
        },

        isOpenLocal(value) {
            this.$emit("update:isOpen", value);
        },

        ["content.options.search"]: helperUtility.debounce(async function () {
            this.resetContent();
        }, 500),
    },

    methods: {
        async getItems($state) {
            this.isLoading = true;
            const { success, data } = await this.$store.dispatch(
                this.action,
                this.content.options
            );
            if (success) {
                const items = data.data;
                if (items.length === this.content.options.perPage) {
                    this.content.options.total = data.meta.total || 0;
                    this.content.items = [...this.content.items, ...items];
                    this.content.options.page += 1;
                    this.isLoading = false;
                    $state.loaded();
                    return;
                }
                this.content.items = [...this.content.items, ...items];
                this.isLoading = false;
                $state.complete();
            }
        },

        onSelect(item) {
            this.$emit("onSelect", item);
            this.isOpenLocal = false;
        },

        resetContent() {
            this.content.options.page = 1;
            this.content.items = [];
            this.infiniteId += 1;
        },
    },
};
</script>
