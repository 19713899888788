const getYoutubeIDFromURL = (url) => {
    const regExp =
        /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url.match(regExp);
    return match && match[7].length === 11 ? match[7] : false;
};

const debounce = (fn, delay) => {
    let timeoutID = null;
    return function () {
        clearTimeout(timeoutID);
        let args = arguments;
        let that = this;
        timeoutID = setTimeout(function () {
            fn.apply(that, args);
        }, delay);
    };
};

const copyText = async (text) => await navigator.clipboard.writeText(text);

const formatFullName = ({ prefix, first_name, last_name }) => {
    return `${prefix} ${first_name} ${last_name}`;
};

const truncateText = (input, length) =>
    input.length > length ? `${input.substring(0, length)}...` : input;

const helperUtility = {
    getYoutubeIDFromURL,
    debounce,
    copyText,
    formatFullName,
    truncateText,
};

export default helperUtility;
