<template>
    <p class="secondary--text subtitle-2 text-uppercase font-weight-black mb-2">
        <span>
            <slot></slot>
        </span>
        <span
            class="ml-1 font-weight-regular text-lowercase"
            v-if="optional || hint"
            >({{ hintLabel }})</span
        >
        <v-tooltip bottom v-if="required && !optional">
            <template v-slot:activator="{ on, attrs }">
                <span
                    class="ml-1 font-weight-bold error--text"
                    v-bind="attrs"
                    v-on="on"
                    >*</span
                >
            </template>
            Required
        </v-tooltip>
    </p>
</template>

<script>
export default {
    name: "base-input-label",

    props: {
        optional: {
            type: Boolean,
            default: false,
        },

        required: {
            type: Boolean,
            default: false,
        },

        hint: {
            type: String,
            default: "",
        },
    },

    computed: {
        hintLabel() {
            let label = "";
            if (this.optional) label = "optional";
            if (this.hint) label = this.hint;
            if (this.optional && this.hint) label = `optional - ${this.hint}`;

            return label;
        },
    },
};
</script>
